import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  combineLatestWith,
  debounceTime,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FacetIconComponent} from '@app/icons/facet-icon.component';
import {MatSliderModule} from '@angular/material/slider';
import {FacetField, FacetType} from '@app/models';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'facet-range-accordion',
  imports: [
    CommonModule,
    FacetIconComponent,
    ChevronIcon,
    MatSliderModule,
    FormsModule,
    CurrencyPipe,
  ],
  templateUrl: './facet-range-accordion.component.html',
  styleUrls: ['./facet-range-accordion.component.css'],
})
export class FacetRangeAccordionComponent implements OnInit, OnDestroy {
  readonly FacetType = FacetType;

  @Input({required: true})
  get activeFacet(): string | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: string | null) {
    this._activeFacet = activeFacet;
  }
  private _activeFacet: string | null = null;

  @Input({required: true}) facetType!: FacetType.duration | FacetType.price;

  maxOverflow = false;

  step = 0;

  max: number | null = null;
  min: number | null = null;
  selectedMax: number | null = null;
  selectedMin: number | null = null;

  @Input({required: true}) options$!: Observable<{
    Min: number | null;
    Max: number | null;
  }>;

  @Input({required: true}) selected$!: Observable<{
    Min: number | null;
    Max: number | null;
  }>;

  @Input({required: true}) fields: FacetField[] = [];
  @Input({required: true}) defaultLabel = '';
  @Input() icon = '';

  @Input() actionLabel: string | null = null;

  // outputs
  @Output() toggleFacet = new EventEmitter<FacetField>();
  @Output() updateRange = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
  }>();
  @Output() clearFacet = new EventEmitter<FacetField[]>();
  @Output() performAction = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    const minField =
      this.fields.find(field => field.startsWith('min')) ?? this.fields[0];
    const maxField =
      this.fields.find(field => field.startsWith('max')) ?? this.fields[1];

    const selectedDebounce$ = this.selected$.pipe(debounceTime(400));

    this.options$
      .pipe(combineLatestWith(selectedDebounce$), takeUntil(this.destroy$))
      .subscribe({
        next: ([options, selected]) => {
          if (options.Max !== null && options.Min !== null) {
            const diff = options.Max - options.Min;
            let step = 0;
            let min = Math.round(options.Min);
            let max = Math.round(options.Max);
            let maxOverflow = false;
            if (diff > 0) {
              switch (this.facetType) {
                case FacetType.duration: {
                  let numSteps: number;
                  let ceiling: number;
                  if (diff < 11) {
                    numSteps = diff;
                    min = options.Min;
                    max = options.Max;
                    ceiling = options.Max;
                  } else {
                    numSteps = 10;
                    min = Math.ceil(options.Min / 10) * 10;
                    ceiling = min + numSteps;
                    max = Math.min(ceiling, Math.floor(options.Max / 10) * 10);
                  }

                  if (options.Max > ceiling) {
                    maxOverflow = true;
                  } else {
                    maxOverflow = false;
                  }
                  step = (max - min) / numSteps;
                  break;
                }
                case FacetType.price: {
                  const numSteps = 10;
                  min = Math.ceil(options.Min / 100) * 100;
                  const ceiling = min + numSteps * 1000;
                  max = Math.min(ceiling, Math.floor(options.Max / 100) * 100);
                  if (options.Max > ceiling) {
                    maxOverflow = true;
                  } else {
                    maxOverflow = false;
                  }
                  step = (max - min) / numSteps;
                }
              }
            }

            this.max = max;
            this.min = min;
            this.step = step;
            this.maxOverflow = maxOverflow;

            if (selected.Max === null) {
              this.selectedMax = max;
            } else if (selected.Max > max) {
              this.clearFilters([maxField]);
            } else {
              // set selectedMax to closest step
              if ((selected.Max - min) % step === 0) {
                this.selectedMax = selected.Max;
              } else {
                const closest =
                  min + Math.round((selected.Max - min) / step) * step;
                this.updateRange.emit({
                  FacetName: maxField,
                  FacetValue: closest.toString(),
                });
              }
            }

            if (selected.Min === null) {
              this.selectedMin = min;
            } else if (selected.Min < min) {
              this.clearFilters([minField]);
            } else {
              // set selectedMin to closest step
              if ((selected.Min - min) % step === 0) {
                this.selectedMin = selected.Min;
              } else {
                const closest =
                  min + Math.round((selected.Min - min) / step) * step;
                this.updateRange.emit({
                  FacetName: minField,
                  FacetValue: closest.toString(),
                });
              }
            }
          } else {
            this.max = null;
            this.min = null;
            this.selectedMax = null;
            this.selectedMin = null;
          }
        },
        error: () => {
          this.max = null;
          this.min = null;
          this.selectedMax = null;
          this.selectedMin = null;
        },
      });
  }

  facetToggle(field: FacetField) {
    this.toggleFacet.emit(field);
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  formatCurrencyLabel(value: number): string {
    return `$${value.toLocaleString('en-US')}`;
  }

  minChange(newVal: string) {
    const field =
      this.fields.find(field => field.startsWith('min')) ?? this.fields[0];
    if (parseInt(newVal) === this.min) {
      this.clearFilters([field]);
    } else {
      this.updateRange.emit({
        FacetName: field,
        FacetValue: newVal,
      });
    }
  }

  maxChange(newVal: string) {
    const field =
      this.fields.find(field => field.startsWith('max')) ?? this.fields[1];
    if (parseInt(newVal) === this.max) {
      this.clearFilters([field]);
    } else {
      this.updateRange.emit({
        FacetName: field,
        FacetValue: newVal,
      });
    }
  }

  clearFilters(fields: FacetField[]) {
    this.clearFacet.emit(fields);
  }

  actionClick() {
    this.performAction.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
