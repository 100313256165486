<button (click)="facetToggle(fields[0])" [attr.aria-expanded]="activeFacet === fields[0]" [attr.aria-controls]="'sect-' + fields[0]" [id]="'accordion-' + fields[0]" [class]="activeFacet === fields[0] ? 'border-tertiary-100' : 'border-transparent'" class="relative z-10 w-full text-sm font-bold rounded-md border-2 hover:border-tertiary-100 transition-colors duration-200 group">
  <span [class]="activeFacet === fields[0] ? 'shadow-none' : ''" class="tray w-full h-[46px] items-center bg-white rounded shadow-inset-md group-hover:shadow-none">
    @if (icon) {
      <span [class]="activeFacet === fields[0] ? 'bg-tertiary-100' : ''" class="flex justify-center items-center min-w-0 h-[30px] rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
        <facet-icon [icon]="icon" fill="white"></facet-icon>
      </span>
    }
    @if (selected$ | async; as selected) {
      @if (selected.Max !== null && selected.Min !== null) {
        <span class="min-w-0 inline-block text-left">
          <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
          <span class="flex leading-4 font-normal">
            @if (selected.Max === selected.Min) {
              @if (facetType === FacetType.price) {
                <span class="inline-block truncate">{{selected.Max | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
              } @else {
                <span class="inline-block truncate">{{selected.Max}} Nights</span>
              }
            } @else {
              @if (facetType === FacetType.price) {
                <span class="inline-block truncate">{{selected.Min | currency : 'USD' : 'symbol' : '1.0-0'}} - {{selected.Max | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
              } @else {
                <span class="inline-block truncate">{{selected.Min}} - {{selected.Max}} Nights</span>
              }
            }
          </span>
        </span>
      } @else if (selected.Max !== null) {
        <span class="min-w-0 inline-block text-left">
          <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
          <span class="flex leading-4 font-normal">
            @if (facetType === FacetType.price) {
              <span class="inline-block truncate">Max {{selected.Max | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
            } @else {
              <span class="inline-block truncate">Max {{selected.Max}} Nights</span>
            }
          </span>
        </span>
      } @else if (selected.Min !== null) {
        <span class="min-w-0 inline-block text-left">
          <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
          <span class="flex leading-4 font-normal">
            @if (facetType === FacetType.price) {
              <span class="inline-block truncate">Min {{selected.Min | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
            } @else {
              <span class="inline-block truncate">Min {{selected.Min}} Nights</span>
            }
          </span>
        </span>
      } @else {
        <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
      }
    } @else {
      <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
    }
    <span [class]="activeFacet === fields[0] ? 'text-tertiary-100' : ''" class="min-w-0 flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
      <i [ngClass]="activeFacet === fields[0] ? 'rotate-180' : 'rotate-0'"
      class="relative inline-block -top-px ml-2 transform transition-transform duration-200">
        <icon-chevron></icon-chevron>
      </i>
    </span>
  </span>
</button>
<div [id]="'sect-' + fields[0]" role="region" [attr.aria-labelledby]="'accordion-' + fields[0]" class="bg-white">
  <div [class]="activeFacet === fields[0] ? 'block' : 'hidden'" class="px-4 py-2">
    <div>
      <button (click)="clearFilters(fields)"
        class="w-full py-3 rounded focus:outline-tertiary-200 text-xs font-bold bg-true-gray-700 cursor-pointer text-white">Clear Selection</button>
    </div>
    <div class="opt-out h-32">
      <div class="flex justify-center">
        @if (max === null || min === null) {
          <div class="my-3 text-center italic text-gray-400">No Matching Results</div>
        } @else {
          <div class="w-full pt-8 pb-5">
            <div class="flex items-center">
              <div class="facet-range-slider grow">
                <mat-slider discrete showTickMarks [step]="step" [max]="max" [min]="min" [displayWith]="facetType === FacetType.price ? formatCurrencyLabel : formatLabel" class="w-full">
                  <input matSliderStartThumb [(ngModel)]="selectedMin" (change)="minChange(startSlider.value)" #startSlider>
                  <input matSliderEndThumb [(ngModel)]="selectedMax" (change)="maxChange(endSlider.value)" #endSlider>
                </mat-slider>
              </div>
            </div>
            <div class="flex justify-between text-sm font-bold">
              @if (facetType === FacetType.price) {
                <div>{{min | currency : 'USD' : 'symbol' : '1.0-0'}}</div>
              } @else {
                <div>{{min}} Nights</div>
              }
              @if (facetType === FacetType.price) {
                <div>{{max | currency : 'USD' : 'symbol' : '1.0-0'}}{{maxOverflow ? '+' : ''}}</div>
              } @else {
                <div>{{max}}{{maxOverflow ? '+' : ''}} Nights</div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>